







































































































import { Component, Vue } from "vue-property-decorator";
import { validationMixin } from "vuelidate";
import { email, required } from "vuelidate/lib/validators";
import Input from "@/components/form/Input.vue";

@Component({
  components: { Input },
  validations: {
    email: {
      required,
      email,
    },
    password: {
      required,
    },
  },
  mixins: [validationMixin],
})
export default class Login extends Vue {
  showPassword = false;
  email = this.userEmailAfterRegister ? this.userEmailAfterRegister : "";
  password = "";

  get userEmailAfterRegister(): string {
    return this.$store.getters["user/userEmail"];
  }

  async submitHandler(): Promise<void> {
    if (this.email && this.password) {
      const isSuccessFul = await this.$store.dispatch("user/login", {
        email: this.email,
        password: this.password,
      });

      if (isSuccessFul) await this.$router.push({ name: "Home" });
    }
  }
}
